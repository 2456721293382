import { Icon } from '@cb/apricot-react'
import { useState, useEffect } from 'react'
import { Table, Column } from '@cb/apricot-react'

const Customers = ({ product }) => {
  return (
    <>
      <div className="row no-gutters cb-font-weight-medium cb-padding-bottom-4">
        Customers
      </div>
      <div className="col cb-padding-bottom-8">
        <div className="row no-gutters cb-font-weight-regular cb-padding-bottom-2">
          Market Indicators
        </div>
        <div className="row no-gutters">
          {'Market Size: '.concat(
            product.customers && product.customers.marketSize
              ? product.customers.marketSize
              : '?'
          )}
        </div>
        <div className="row no-gutters">
          {'Market Potential: '.concat(
            product.customers && product.customers.marketPotential
              ? product.customers.marketPotential
              : '?'
          )}
        </div>
      </div>
    </>
  )
}

const Users = ({ product, setFeedbackView }) => {
  return (
    <>
      <div className="row no-gutters cb-font-weight-medium cb-padding-bottom-4">
        Users
      </div>
      <div className="col cb-padding-bottom-8">
        <div className="row no-gutters cb-font-weight-regular cb-padding-bottom-2">
          Counts
        </div>
        <div className="row no-gutters">
          <p>
            <Icon
              className="cb-padding-right-8 cb-blue5-color"
              name="raised-pencil"
            />
            {'Students: '.concat(
              product.customers && product.customers.students
                ? product.customers.students
                : '?'
            )}
          </p>
        </div>
        <div className="row no-gutters">
          <p>
            <Icon className="cb-padding-right-8 cb-blue5-color" name="apple" />
            {'Educators: '.concat(
              product.customers && product.customers.educators
                ? product.customers.educators
                : '?'
            )}
          </p>
        </div>
      </div>
      <div className="col cb-padding-bottom-8">
        <div className="row no-gutters cb-font-weight-regular cb-padding-bottom-2">
          Feedback
        </div>
        <div className="row no-gutters cb-font-weight-light">
          {product.feedback ? (
            <div>
              {Math.round(
                100 *
                  (product.helpfulTotal /
                    (product.helpfulTotal + product.notHelpfulTotal))
              )}
              % positive responses out of{' '}
              {product.helpfulTotal + product.notHelpfulTotal}
              <Icon
                style={{ cursor: 'pointer', 'text-decoration': 'underline' }}
                onClick={() => {
                  console.log('Going to feedback view.')
                  setFeedbackView(true)
                }}
                className="cb-padding-left-4 hover-effect cb-blue5-color"
                name="east"
                size="12"
              />
            </div>
          ) : (
            'No feedback data'
          )}
        </div>
      </div>
    </>
  )
}

const Profile = ({ product }) => {
  return (
    <>
      <div className="row no-gutters cb-font-weight-medium cb-padding-bottom-4">
        Profile
      </div>
      <div className="col cb-padding-bottom-8">
        <div className="row no-gutters cb-font-weight-regular cb-padding-bottom-2">
          Product Lifecycle
        </div>
        <div className="row no-gutters">
          {product.vitals && product.vitals.lifecycle
            ? product.vitals.lifecycle
            : 'No Lifecycle Data'}
        </div>
      </div>
      <div className="col cb-padding-bottom-8">
        <div className="row no-gutters cb-font-weight-regular cb-padding-bottom-2">
          Usage Pattern
        </div>
        <div className="row no-gutters">
          {product.vitals && product.vitals.usagePattern
            ? product.vitals.usagePattern
            : 'No Usage Pattern Data'}
        </div>
      </div>
    </>
  )
}

const Composition = ({ product }) => {
  return (
    <>
      <div className="row no-gutters cb-font-weight-medium cb-padding-bottom-4">
        Composition
      </div>
      <div className="col cb-padding-bottom-8">
        <div className="row no-gutters cb-font-weight-regular cb-padding-bottom-2">
          Assets
        </div>
        <div className="row no-gutters">
          {'Asset Count: '.concat(
            product.vitals && product.vitals.assetCount
              ? product.vitals.assetCount
              : '?'
          )}
        </div>
      </div>
      <div className="col cb-padding-bottom-8">
        <div className="row no-gutters cb-font-weight-regular cb-padding-bottom-2">
          Teams
        </div>
        <div className="row no-gutters">
          {`Team Count: ${product.teams.split(',').length}`}
        </div>
        <div className="row no-gutters">{`${product.teams}`}</div>
      </div>
      <div className="col cb-padding-bottom-8">
        <div className="row no-gutters cb-font-weight-regular cb-padding-bottom-2">
          Applications
        </div>
        <div className="row no-gutters">
          {product.appNames.length
            ? product.appNames.map(a => <div>{a}</div>)
            : '?'}
        </div>
      </div>
    </>
  )
}

const Sections = ({ product, setFeedbackView }) => {
  const sections = { /*Financials,*/ Profile, Composition, Users, Customers }

  // Aggregate helpfulness URLs for display on card
  if (product.feedback) {
    product.helpfulTotal = 0
    product.notHelpfulTotal = 0
    product.feedback.forEach(url => {
      product.helpfulTotal += url.helpfulResponseCount
      product.notHelpfulTotal += url.notHelpfulResponseCount
    })
  }

  return (
    <div className="row">
      {Object.entries(sections).map(([sectionName, Section]) => (
        <div
          key={sectionName}
          className="col product-section cb-padding-right-32"
        >
          <Section product={product} setFeedbackView={setFeedbackView} />
        </div>
      ))}
    </div>
  )
}

const Overview = ({ product }) => {
  return (
    <>
      <div className="row cb-padding-top-8 cb-font-weight-medium">
        <div className="col">Overview</div>
      </div>
      <div className="row cb-padding-bottom-16 cb-roboto-light">
        <div className="col">{product.description}</div>
      </div>
    </>
  )
}

const Title = ({ product }) => {
  return (
    <div className="cb-card-title">
      <div>
        <Icon className="cb-padding-right-8 cb-blue5-color" name="acorn" />
        {product.name}
        <span className="cb-font-weight-light">
          {` | ${product.portfolio}`}
        </span>
        <div className="cb-font-weight-light cb-paragraph1-st">
          {`Product Manager: ${
            product.productManager || '?'
          } • Engineering Manager: ${product.engineeringManager || '?'}`}
        </div>
      </div>
    </div>
  )
}

const FeedbackDetails = ({ product, setFeedbackView }) => {
  let rows = []
  product.feedback.forEach(url => {
    rows.push({
      url: url.url,
      rate:
        String(
          Math.round(
            (100 * url.helpfulResponseCount) /
              (url.helpfulResponseCount + url.notHelpfulResponseCount)
          )
        ) + '%',
      count: url.helpfulResponseCount + url.notHelpfulResponseCount,
    })
  })
  rows.sort((a, b) => a.count < b.count)
  return (
    <>
      <Icon
        name="west"
        style={{ cursor: 'pointer', 'text-decoration': 'underline' }}
        onClick={() => setFeedbackView(false)}
        className="row cb-blue5-color cb-padding-bottom-8"
      />
      <div className="row">
        <Table data={rows} condensed={true}>
          <Column field="url" title="Web URL" />
          <Column field="rate" title="Helpful Rate" />
          <Column field="count" title="Count" />
        </Table>
      </div>
    </>
  )
}

const Product = ({ product, selectProduct }) => {
  const [feedbackView, setFeedbackView] = useState(false)
  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        selectProduct(null)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectProduct])

  useEffect(() => {
    console.log(feedbackView)
  }, [feedbackView])

  return (
    <div className="container-fluid cb-padding-top-16">
      <div className="row justify-content-center">
        <div className="col-xs-10 col-md-9">
          <div className="row justify-content-start">
            <div
              className="cb-icon cb-icon-24 cb-x-mark hover-effect cb-padding-16"
              style={{ cursor: 'pointer' }}
              onClick={() => selectProduct(null)}
            />
          </div>
          <div className="cb-card cb-border cb-blue5-border cb-border1 cb-font-weight-light">
            <div className="cb-font-weight-light cb-card-content">
              <Title product={product} />
              <div className="container">
                <Overview product={product} />
                {feedbackView ? (
                  <FeedbackDetails
                    product={product}
                    setFeedbackView={setFeedbackView}
                  />
                ) : (
                  <Sections
                    product={product}
                    setFeedbackView={setFeedbackView}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Product
